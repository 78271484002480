// Generated by Framer (8b11b68)

import * as React from "react";
import { motion, LayoutGroup } from "framer-motion";
import { useOnVariantChange, useActiveVariantCallback, Image, transformTemplate, addFonts, withCSS, addPropertyControls, ControlType, cx, useAddVariantProps, useVariantState, CycleVariantState, Container, getFonts, Stack, useHydratedBreakpointVariants, removeHiddenBreakpointLayers, SSRVariants, GeneratedComponentContext } from "framer";
import { useRandomID } from "https://framer.com/m/framer/randomID.js@^2.0.0";
import Navigation from "../canvasComponent/r3EuSlILg";
import ProductList from "../codeFile/ProductList.tsx";
const NavigationFonts = getFonts(Navigation);
const ProductListFonts = getFonts(ProductList);

const cycleOrder = ["fsQoZINDG", "fEmx4RTlu", "doCSdExZC"];

const breakpoints = {"fsQoZINDG": "(min-width: 1200px)", "fEmx4RTlu": "(min-width: 810px) and (max-width: 1199px)", "doCSdExZC": "(max-width: 809px)"}

const isBrowser = typeof document !== "undefined";

const variantClassNames = {"fsQoZINDG": "framer-v-nkr8pu", "fEmx4RTlu": "framer-v-he6b6x", "doCSdExZC": "framer-v-1kdxfw9"};

if (isBrowser) {
                    removeHiddenBreakpointLayers("fsQoZINDG", breakpoints, variantClassNames);
            }

const humanReadableVariantMap = {"Desktop": "fsQoZINDG", "Tablet": "fEmx4RTlu", "Phone": "doCSdExZC"};

const transitions = {"default": {"duration": 0}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ style, className, width, height, layoutId, variant: outerVariant = "fsQoZINDG", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const [baseVariant, hydratedBaseVariant] = useHydratedBreakpointVariants(variant, breakpoints, false);
const gestureVariant = undefined;
const transition = transitions.default;

const variantProps = React.useMemo(() => ({"fEmx4RTlu": {"fsQoZINDG": {"center": false, "__contentWrapperStyle": {"width": "100%", "height": "auto", "padding": "0px 0px 0px 0px"}}, "AZV_zgXWC": {"style": {"width": "100%"}}, "AZV_zgXWC-container": {"transformTemplate": undefined}}, "doCSdExZC": {"fsQoZINDG": {"center": false, "__contentWrapperStyle": {"width": "100%", "height": "auto", "padding": "0px 0px 0px 0px"}}, "AZV_zgXWC": {"style": {"width": "100%"}}, "AZV_zgXWC-container": {"transformTemplate": undefined}}}), []);

const addVariantProps = useAddVariantProps(hydratedBaseVariant, gestureVariant, variantProps);

const defaultLayoutId = useRandomID();

return (<GeneratedComponentContext.Provider value={{ primaryVariantId: "fsQoZINDG", variantProps: variantProps, variantClassNames: variantClassNames}}>
<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div className="framer-6Fje3" style={{"display": "contents"}}>
<SSRVariants id="fsQoZINDG"><Stack {...restProps} className={cx("framer-nkr8pu", className)} style={{...style}} name="Desktop"  direction="vertical" distribution="start" alignment="center" gap={30} __fromCanvasComponent={true} __contentWrapperStyle={{"width": "100%", "height": "auto", "padding": "0px 0px 0px 0px"}} center={false} transition={transition} ref={ref} {...addVariantProps("fsQoZINDG")}><Container className="framer-12ovfke-container"  transition={transition} {...addVariantProps("k83NcxpMs-container")}><Navigation width="100%" height="100%" layoutId="k83NcxpMs" id="k83NcxpMs" style={{"width": "100%"}} transition={transition} {...addVariantProps("k83NcxpMs")}/></Container><SSRVariants id="AZV_zgXWC-container"><Container className="framer-13uh2g5-container"  transition={transition} {...addVariantProps("AZV_zgXWC-container")}><SSRVariants id="AZV_zgXWC"><ProductList width="100%" height="100%" layoutId="AZV_zgXWC" id="AZV_zgXWC" style={{"width": "100%"}} transition={transition} {...addVariantProps("AZV_zgXWC")}/></SSRVariants></Container></SSRVariants></Stack></SSRVariants>
</motion.div>
</LayoutGroup>
</GeneratedComponentContext.Provider>)

});

const css = [".framer-6Fje3 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-6Fje3 .framer-nkr8pu { position: relative; overflow: hidden; width: 1200px; height: min-content; background-color: #ffffff; }", ".framer-6Fje3 .framer-12ovfke-container { position: relative; width: 100%; height: auto; flex: none; }", ".framer-6Fje3 .framer-13uh2g5-container { position: relative; width: 960px; height: auto; flex: none; }", "@media (min-width: 1200px) { .framer-6Fje3 .hidden-nkr8pu { display: none !important; } }", "@media (min-width: 810px) and (max-width: 1199px) { .framer-6Fje3 .hidden-he6b6x { display: none !important; } .framer-6Fje3 .framer-nkr8pu { width: 810px; height: min-content; } .framer-6Fje3 .framer-13uh2g5-container { width: 100%; height: auto; right: auto; bottom: auto; left: auto; top: auto; flex: none; aspect-ratio: unset; }}", "@media (max-width: 809px) { .framer-6Fje3 .hidden-1kdxfw9 { display: none !important; } .framer-6Fje3 .framer-nkr8pu { width: 390px; height: min-content; } .framer-6Fje3 .framer-13uh2g5-container { width: 100%; height: auto; right: auto; bottom: auto; left: auto; top: auto; flex: none; aspect-ratio: unset; }}"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1755
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName": "variant", "data": {"default": {"layout": ["fixed", "auto"]}, "fEmx4RTlu": {"layout": ["fixed", "auto"]}, "doCSdExZC": {"layout": ["fixed", "auto"]}}}
 * @framerResponsiveScreen
 */
const Framerwk8fi4NQj: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default Framerwk8fi4NQj;

Framerwk8fi4NQj.displayName = "Products";

Framerwk8fi4NQj.defaultProps = {"width": 1200, "height": 1755};

addFonts(Framerwk8fi4NQj, [...NavigationFonts, ...ProductListFonts]);